<template>
  <div id="product">
    <div class="main">
      <div class="container">
        <slider ref="slider" :options="options" @slide="slide($event)">
          <!-- <slideritem v-for="(item,index) in data.url_image" :key="index" style="width:80%"> -->
          <slideritem v-for="(item,index) in data.url_media" :key="index">
            <div class="countdown" v-if="data && data.countdown">
              <Countdown :date="date" @finish="setCountdown()"/>
            </div>
            <span v-if="data.status === 'sold'" class="shop-badge-notify" :style="soldColor ? {'background-color': soldColor} : {'background-color': 'rgb(218,41,28)'}"> {{ $t('shop.sold') }} </span>
            <span v-if="data.is_coming_soon !== 'NO'" class="shop-badge-notify-soon"> SOON </span>
            <!-- <img v-bind:src="item" width="100%" :alt="item.img1" /> -->
            <img v-if="item.type === 'image'" v-bind:src="item.url" class="img" :alt="item.img1">
            <div v-else class="slider-video">
              <video
                autoplay
                muted
                playsinline
                loop
                height="525px"
                width="auto" 
                :id="'video-'+ index"
              >
                <source :src="item.url">
              </video>
               <!-- <v-btn
                :color="soldColor ? soldColor : 'blue'"
                class="mx-2 slider-video-button"
                fab
                small
                @click="play(index)"
              >
                <v-icon v-if="playindicator && playindicator[index].play === false" color="white">
                  mdi-play
                </v-icon>

                <v-icon v-if="playindicator && playindicator[index].play === true" color="white">
                  mdi-pause
                </v-icon>

              </v-btn> -->
            </div>
          </slideritem>
          <div slot="loading">loading...</div>
        </slider>
        <v-btn
          class="mx-2 nav-left"
          color="#000"
          fab
          small
          @click="previous()"
        >
          <v-icon color="white">
            mdi-chevron-left
          </v-icon>
        </v-btn>

         <v-btn
          class="mx-2 nav-right"
          color="#000"
          fab
          small
          @click="next()"
        >
          <v-icon color="white">
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
      <div class="pw-thumbnail">
        <Thumbnail 
          :media="data.url_media" 
          :index="index" 
          @moveTo="moveTo($event)" 
          :video-thumbnail="data.vid_thumbnail"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Countdown from '@/components/Countdown'
  import { slider, slideritem } from 'vue-concise-slider'
  import Thumbnail from '@/components/Shop/Thumbnail'

  export default {
    name: 'ProductWeb',
    components: { Countdown,slider, slideritem, Thumbnail },
    props: {
      data: {
        type: Object,
        default: () => {
          return {}
        }
      },
      playindicator: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    computed: {
      ...mapState([
        'isMobile'
      ]),
      date () {
        if (this.data.countdown) {
          let t = this.data.end_at.split(/[- :]/);
          // Apply each element to the Date function
          let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
          return this.ceilValue((new Date(d)).getTime() / 1000)
        } else {
          return this.ceilValue((new Date()).getTime() / 1000)
        }
      }
    },
    methods: {
      setCountdown () {
        this.$emit('countdown')
        this.data.countdown = false
      },
      play (index) {
        let id = 'video-' + index
        let video = document.getElementById(id)

        if (video) {
          if (this.playindicator[index].play) {
            video.pause()
            this.playindicator[index].play = false
          } else {
            video.play()
            this.playindicator[index].play = true
          }
        }
      },
      moveTo (index) {
        this.$refs.slider.$emit('slideTo', index)
      },
      slide (data) {
        this.playVideo(data.currentPage)
      },
      playVideo (index) {
        if (this.playindicator[index].type === 'video') {
          this.play(index)
        } 
        else {
          this.setPauseVideo()
        }
      },
      setPauseVideo () {
        this.playindicator.forEach((item,index) => {
          if (item.play === true) {
            this.play(index)
          }
        })
      },
      next () {
        if (this.index === this.data.url_media.length - 1 ) {
          this.index = 0
        } 
        else {
          this.index += 1
        }
        this.$refs.slider.$emit('slideTo', this.index)
      },
      previous () {
        if (this.index === 0 ) {
          this.index = this.data.url_media.length - 1
        } 
        else {
          this.index -= 1
        }
        this.$refs.slider.$emit('slideTo', this.index)
      },
    },
    data () {
      return {
        options: {
          currentPage: 0,
          direction: 'horizontal',
          pagination: false
          // direction: 'vertical'
        },
        // options: {
        //   currentPage: 0,
        //   speed: 300,
        //   itemAnimation: true,
        //   centeredSlides: true,
        //   thresholdDistance: 100,
        //   thresholdTime: 300,
        //   loopedSlides: 2,
        //   slidesToScroll: 1,
        //   loop: false
        // },
        soldColor: process.env.VUE_APP_LABEL_SOLD,
        index: 0
      }
    },
    mounted () {}
  }
</script>

<style scoped>
  #product {
    box-sizing: border-box
  }

  .main {
    width: 100%;
    position: relative;
    background-size: cover;
    margin: 50px auto;
  }
  
  .container {
    width: 100%;
    /* height: 750px; */
    margin: 0px auto;
    margin-top: 10%;
    position: relative;
    overflow: hidden;
  }

  .container .img {
    max-width: 350px;
    /* height: 720px; */
    position: relative;
  }
  
  .main .nav {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 45px;
    color: #fff;
    background-color: #C0C0C0;
    cursor: pointer;
    transition: all .5s ease-in-out;
    text-align: center;
    opacity: 0.5;
  }
  
  
  .main:hover .nav{ opacity: 0.8 }
  
  .main .nav:hover{
    background: rgba(10,10,10,0.6);
    color: orangered;
  }
  
  .next{top:0}
  .previous{bottom: 0}

  .nav-left {
    position: absolute;
    top: 50%;
    left: 6%;
    transform: translateY(-50%);
    z-index: 1;
    /* background-color: black !important; */
    opacity: 0.2;
  }

  .nav-right {
    position: absolute;
    top: 50%;
    right: 6%;
    transform: translateY(-50%);
    z-index: 1;
    /* background-color: black !important; */
    opacity: 0.2;
  }

  /* .slider-item {
    transform:scale(1.0);
    margin: 0 5px;
    transition-timing-function: ease;
    transition-duration: 300ms;
  }

  .slider-item.slider-active {
    transform:scale(1.0);
    z-index: 999;
  }

  .slider-item.slider-active-copy {
    transform:scale(1.0);
    z-index: 999;
  } */

  .countdown {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  .shop-badge-notify{
    /* opacity: 0.5; */
    background-color: rgb(218,41,28);
    color: #FFFFFF;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    top: 2%;
    left: 2%;
    z-index: 100;
  }

  .shop-badge-notify-soon{
    /* opacity: 0.5; */
    background-color: #e7a635;
    color: #FFFFFF;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    top: 2%;
    left: 2%;
    z-index: 100;
  }
  .slider-video {
    position: relative;
  }

  .slider-video-button {
    position: absolute;
    top:5%;
    right:8%;
  }

  .pw-thumbnail {
    margin: 20px 10%;
  }
                 
</style>
