<template>
  <div id="remind-me">
    <v-flex xs12>
      <h1>{{ title }}</h1>

      <div :class="!isMobile ? 'mt-5' : ''">
        <v-flex xs12 sm12 md12>
          <v-form ref="form" class="mt-9" v-model="valid" lazy-validation>
            <v-layout wrap>
               <v-flex xs12>
               <v-combobox
                  v-model="variant"
                  :items="variants"
                  label="Size"
                  :outlined="isMobile"
                  :rules="variantRules"
                  item-text="ukuran"
                  item-value="sku_product"
                ></v-combobox>
               </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="qty"
                  type="number"
                  min="1"
                  ref="qty"
                  label="Quantity"
                  :outlined="isMobile"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                 <v-textarea
                  v-model="message"
                  ref="message"
                  label="Message"
                  :outlined="isMobile"
                  :rows="isMobile ? '3' : '3'"
                  required
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-flex xs12 class="mb-9">
              <v-btn
                class="mt-5"
                tile
                :color="bgColor"
                width="100%"
                height="44px"
                @click="submit"
              >
                <span class="btn-text"> Submit </span>
              </v-btn>
              <!-- <v-btn
                class="mt-1"
                outlined
                tile
                width="100%"
                height="44px"
                @click="$emit('close')"
              >
                <span class="btn-text"> {{ $t("cancel") }} </span>
              </v-btn> -->
            </v-flex>
          </v-form>
        </v-flex>
      </div>
    </v-flex>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "RemindMe",
  computed: {
    ...mapState(["isMobile", "userEmail", "token", "bgColor"]),
  },
  props: {
    variants: {
      type: Array,
      default: ()=> {
        return []
      }
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      variant:'',
      qty: '1',
      message: '',
      valid: true,
      variantRules: [ v => !!v || 'Size is required' ]
    };
  },
  methods: {
    submit () {
      let data = {
        qty: this.qty,
        message: this.message,
        variant: this.variant.sku_product
      }
      if (this.$refs.form.validate()) {
        this.$emit('submit', data)
      }
    }
  },
  mounted() {}
};
</script>

<style scoped>
  #remind-me {
    padding: 20px;
    text-align: center;
    background-color: #fff;
  }

  .text {
    display: block;
    font-size: 0.8rem;
    color: #000;
  }

  .btn-text {
    display: block;
    font-size: 0.8rem;
    color: #fff;
  }

  h1 {
    font-size: 24px;
    font-weight: 500;
  }
</style>
