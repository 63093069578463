<template>
  <div class="fs-slider">
    <v-btn class="close-button" icon large @click="$emit('close')">
      <v-icon large> mdi-close </v-icon>
    </v-btn>
    <slider ref="slider" :options="options">
      <slideritem v-for="(item,index) in data.url_image" :key="index">
        <div>
          <img v-bind:src="item" :width="isMobile ? '100%' : 'auto'" :height="isMobile ? 'auto' : '100%'" :alt="item.img1">
        </div>
      </slideritem>
      <div slot="loading">loading...</div>
    </slider>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { slider, slideritem } from 'vue-concise-slider'

  export default {
    name: 'ProductSlider',
    components: {
      slideritem,
      slider
    },
    props: {
      data: {
        type: Object,
        default: () => {
          return {}
        }
      },
      options: {
        type: Object,
        default: () => {
          return {
            currentPage: 0,
            direction: 'horizontal',
            pagination: false,
            loop:true
          }
        }
      }
    },
    computed: {
      ...mapState([
        'isMobile'
      ])
    }
  }
</script>

<style scoped>
.fs-slider {
  background-color: #fff;
}
.close-button {
  position: absolute;
  top: 2%;
  right: 2%;
  z-index: 999;
}
</style>