<template>
  <div :class="disabled? 'button-add disabled' : 'button-add'" @click="submit">
    <p class="mb-0">
      <v-icon small>mdi-plus</v-icon>
      <span  :class="disabled? 'ba-text disabled' : 'ba-text'">ADD PRODUCT</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ButtonAdd',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    submit () {
      if (!this.disabled) {
        this.$emit('submit')
      }
    }
  }
}
</script>

<style scoped>
.button-add {
  padding: 12px;
  text-align: center;
  background: #FFFFFF;
  border: 1px dashed #000000;
  border-radius: 5px;
  cursor: pointer;
}
.disabled{
  cursor: not-allowed;
  opacity: 0.5;
}
.ba-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #666261;
}
</style>