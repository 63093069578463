<template>
  <div class="bundling">
    <div class="b-header" elevation="1">
      <h1 class="b-title"> PICK VARIANT </h1>
      <v-icon class="b-icon" @click="closeBundling()">mdi-close</v-icon>
    </div>

    <div class="b-info mb-4">
      <span v-if="brand === '5'" class="b-sub">
        Kamu hanya dapat membeli 1 pcs untuk tiap varian
      </span>
       <span v-else class="b-sub">
        Kamu dapat membeli {{data.max_bundling}} pcs dengan varian apapun
      </span>
    </div>
    <div class="b-content pa-4">
      <div class="b-content-item mt-4" v-for="(item, i) in products" :key="item.id">
        <BundlingForm
          :list="list[i]"
          :variantList="item.variantList"
          :selectedVariant="item.variant"
          :qty="item.qty"
          :sameProduct="item.sameProduct"
          @selected_product="getProduct($event, i)"
          @selected_variant="confirmVariant($event, i)"
          @plus="confirmPlus(i)"
          @minus="minus(i)"
        />
      </div>

      <ButtonAdd class="mt-4 mb-custom" @submit="addProduct()" :disabled="(this.products && this.products.length >= data.max_bundling) || (this.list && (this.list[0] && this.list[0].length === 0) && (this.list[1] && this.list[1].length === 0))" />

      <div class="b-action mt-16 mb-16">
        <v-btn tile color="black" width="75%" height="44px" @click="submit()" :disabled="data.status === 'sold' || data.is_coming_soon !== 'NO' || (this.list && (this.list[0] && this.list[0].length === 0) && (this.list[1] && this.list[1].length === 0))">
          <span class="btn-text" v-if="data.status === 'sold' || data.is_coming_soon !== 'NO'"> 
            <b v-if="data.status === 'sold'"> {{ $t('shop.empty-product') }}</b>
            <b v-if="data.is_coming_soon !== 'NO'"> COMING SOON</b> 
          </span>
          <span class="btn-text" v-else> {{ $t('shop.add-to-cart') }} </span>
        </v-btn>
      </div>
    </div>

    <!-- <div class="b-action">
      <v-btn tile color="black" width="75%" height="44px" @click="submit()" :disabled="data.status === 'sold' || data.is_coming_soon !== 'NO'">
        <span class="btn-text" v-if="data.status === 'sold' || data.is_coming_soon !== 'NO'"> 
          <b v-if="data.status === 'sold'"> {{ $t('shop.empty-product') }}</b>
          <b v-if="data.is_coming_soon !== 'NO'"> COMING SOON</b> 
        </span>
        <span class="btn-text" v-else> {{ $t('shop.add-to-cart') }} </span>
      </v-btn>
    </div> -->

    <v-snackbar
      v-model="snackbar"
      dark
      timeout="1000"
      :top="true"
    >
      <span v-html="text"></span>

      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="snackbar = false"
        >

          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import BundlingForm from '@/components/Bundling/BundlingForm'
import ButtonAdd from '@/components/Bundling/ButtonAdd'
import axios from '@/axios'

export default {
  name: 'Bundling',
  components: { BundlingForm, ButtonAdd },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      products: [],
      list: [],
      snackbar: false,
      text: '',
      brand: process.env.VUE_APP_BRAND
    }
  },
  methods: {
    closeBundling () {
      this.$emit('close')
    },
    initProducts () {
      this.products = [
        {
          id: 1,
          product_code: '',
          product_name: '',
          variant: {},
          variantList: [],
          sameProduct: false,
          qty: 0
        },
        {
          id: 2,
          product_code: '',
          product_name: '',
          variant: {},
          variantList: [],
          sameProduct: false,
          qty: 0
        }
      ]
    },
    addProduct () {
      if (this.products.length === 0) {
        this.list.push(this.data.bundling_option_1.filter(item => item.variant && item.variant.length > 0))
      } else if (this.products.length === 1) {
        this.list.push(this.data.bundling_option_2.filter(item => item.variant && item.variant.length > 0))
      } else {
        let list1 = this.data.bundling_option_1.filter(item => item.variant && item.variant.length > 0)
        let list2 = this.data.bundling_option_2.filter(item => item.variant && item.variant.length > 0)
        this.list.push(list1.concat(list2))
      }
      this.products.push({
        id: this.products[this.products.length - 1 ].id + 1,
        product_code: '',
        product_name: '',
        variant: {},
        variantList: [],
        sameProduct: false,
        qty: 0
      })
    },
    // getProduct ($event, index) {
    //   this.products[index].sameProduct = false
    //   let sameProduct = this.products.find(item => item.product_code === $event)
    //   setTimeout(() => {
    //     if (sameProduct) {
    //       this.products[index].sameProduct = true
    //       this.products[index].product_code = ''
    //       this.products[index].product_name = ''
    //       this.products[index].qty = 0
    //       this.products[index].variant =  {}
    //       this.products[index].variantList = []
    //       this.snackbar = true
    //       this.text = 'Tidak bisa memiliih produk yang sama'
    //     } else {
    //       let data = this.list[index].find(item => item.product_code === $event)
    //       this.products[index].product_code = data.product_code
    //       this.products[index].product_name = data.product_name
    //       this.products[index].variantList = data.variant
    //       this.products[index].sameProduct = false
    //       this.products[index].qty = 0
    //       this.products[index].variant =  {}
    //     }
    //   }, 500)
    // },
    getProduct ($event, index) {
      let data = this.list[index].find(item => item.product_code === $event)
      this.products[index].product_code = data.product_code
      this.products[index].product_name = data.product_name
      this.products[index].variantList = data.variant
      this.products[index].sameProduct = false
      this.products[index].qty = 0
      this.products[index].variant =  {}
    },
    confirmVariant ($event, index) {
      let sameProduct = this.products.find(item => item.variant.sku_product === $event.sku_product)
      if (sameProduct) {
        this.snackbar = true
        this.text = 'Anda telah memilih produk ini dengan ukuran yang sama'
      } else {
        this.checkCart(index, false, true, $event)
      }
    },
    getVariant ($event, index) {
      this.products[index].variant = $event
      this.products[index].qty = 1
    },
    plus (index) {
      // if ((this.products[index].qty + 1 ) <= 2) {
        this.products[index].qty += 1
      // }
    },
    confirmPlus (index) {
      if (this.brand === '5') {
        if ((this.products[index].qty + 1 ) <= 1) {
          this.checkCart(index, true, false, null)
        } else {
          this.snackbar = true
          this.text = 'Pembelian maksimal adalah 1 pcs per produk'
        }
      } else {
        if ((this.products[index].qty + 1 ) <= this.data.max_bundling) {
          this.checkCart(index, true, false, null)
        } else {
          // this.$store.commit('setSnackbar', true)
          // this.$store.commit('setText', 'Pembelian maksimal adalah '+ 2 + ' pcs per produk')
          this.snackbar = true
          this.text = 'Pembelian maksimal adalah '+ this.data.max_bundling + ' pcs per produk'
        }
      }
    },
    minus (index) {
      if ((this.products[index].qty -1 ) < 1) {
        if (this.brand === '5') {
          if (this.products.length > 2) {
            this.products.splice(index, 1)
          }
        } else {
          if (this.products.length > 1) {
            this.products.splice(index, 1)
          }
        }
      } else {
        this.products[index].qty -= 1
      }
    },
    initList () {
      this.list.push(this.data.bundling_option_1.filter(item => item.variant && item.variant.length > 0))
      this.list.push(this.data.bundling_option_2.filter(item => item.variant && item.variant.length > 0))
    },
    checkCart(index, plus, variant, variantValue) {
      this.$store.commit('setIsLoading', true)

      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        is_offline: true
      }

      axios.post('/api/v1/orders/add_to_cart', {
          sku_product: variantValue && variantValue.sku_product ? variantValue.sku_product : this.products[index].variant.sku_product,
          qty: 1,
          old_qty: this.products[index].qty,
          total_cart_qty: this.products[index].qty + 1
      })
        .then(response => {
          // this.$store.commit('setSnackbar', true)
          // this.$store.commit('setText', response.data.message)
          this.text = response.data.message
          if (plus) {
            this.plus(index) 
          }

          if (variant) {
            this.getVariant(variantValue, index)
          }
        })
        .catch(error => {
          this.errorHandling(error)
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false)
        })
    },
    validate () {
      let total = 0
      let isVariant = true
      this.products.forEach(item => {
        total += item.qty
        if (!item.variant.sku_product) {
          isVariant = false
        }
      })

      if (isVariant) {
        if (total < this.data.max_bundling) {
          // this.$store.commit('setSnackbar', true)
          // this.$store.commit('setText', 'Pembelian belum mencapai '+ this.data.max_bundling + ' pcs')

          this.snackbar = true
          this.text = 'Pembelian belum mencapai '+ this.data.max_bundling + ' pcs'
          
          return false
        } else if (total > this.data.max_bundling) {
          // this.$store.commit('setSnackbar', true)
          // this.$store.commit('setText', 'Pembelian maksimal adalah '+ this.data.max_bundling + ' pcs')

          this.snackbar = true
          this.text = 'Pembelian maksimal adalah '+ this.data.max_bundling + ' pcs'
          return false
        } else {
          return true
        }
      } else {
        if (total === Number(this.data.max_bundling)) {
          return true
        } else {
          // this.$store.commit('setSnackbar', true)
          // this.$store.commit('setText', 'Anda belum memilih ukuran atau variant')

          this.snackbar = true
          this.text = 'Anda belum memilih ukuran atau variant atau quantity belum sesuai'
          return false
        }
      }
    },
    getSubmittedProduct () {
      return this.products.filter(item => (item.product_code !== '' && item.qty !== 0))
    },
    submit () {
      if (this.validate()) {
        this.$emit('addtocart', {
          data: this.data,
          bundling: this.getSubmittedProduct()
        })
      }
    }
  },
  mounted () {
    this.initProducts()
    this.initList()
  }
}
</script>

<style scoped>
.bundling {
  /* background-color: #F5F5F5; */
  background-color: #fff;
  position: relative;
  height: 100vh;
}
.b-header {
  padding: 18px 12px;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #C0C0C0;
  /* background-color: #fff; */
}
.b-content {
  max-height: 80vh;
  overflow-y: auto;
}
/* .b-content-item {
  background-color: #fff;
} */
.b-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.b-icon {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}
.b-info {
  padding: 12px;
  margin: 12px;
  background-color: #FFDFA5;
}
.b-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
  color: #000000;
}
.btn-text {
  color: #FFFFFF;
  text-transform: uppercase;
}
.b-action {
  /* position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; */
  padding: 12px 0;
  /* border-top: 1px solid #C0C0C0; */
  background-color: #fff;
}
/* .mb-custom {
  margin-bottom: 300px;
} */
</style>